import ImgixClient from 'imgix-core-js'

const RESOLUTIONS = [210, 420, 520, 768, 1024, 1400, 1600, 1920, 2560]

const VARIANTS = {
  natural: null,
  square: 1,
  landscape: 1.4,
  panoramic: 2.5,
  portrait: 0.8
}

let imgixClient

imgixClient = new ImgixClient({
  domain: 'magda-butrym.imgix.net',
  secureURLToken: 'NxhFxVDcBf6ZcPpd'
})

export function getImgixSrc(src, width, ratio = null) {
  let h = false

  if (ratio) {
    h = Math.round(width / ratio)
  }

  const params = {
    w: width,
    h: h,
    fit: 'crop',
    auto: 'format',
    q: 80
  }

  let urlToTransform = src
  if (urlToTransform.substring(0, 2) === '//') {
    urlToTransform = 'https:' + urlToTransform
  }

  return imgixClient.buildURL(urlToTransform, params)
}

function getSrcset(image, ratio, extraInfo = {}) {
  let images = []

  RESOLUTIONS.forEach((res) => {
    let w = res
    let h = Math.round(res / ratio)

    const params = {
      ...extraInfo,
      w: w,
      h: h,
      fit: 'crop',
      // fm: "webp",
      auto: 'format',
      q: 80
    }

    let url = imgixClient.buildURL(image.originalSrc, params)

    images.push({
      w: w,
      h: h,
      url: url
    })
  })

  return images
}

function createImage(image) {
  // if (!imgixClient) {
  //     throw new Error("createImage function can be run only on server-side!")
  // }

  let ret = {
    alt: image.alt || null,
    title: image.alt || null,
    originalSrc: image.originalSrc,
    variants: []
  }

  Object.keys(VARIANTS).forEach((variantName) => {
    const aspectRatio = variantName === 'natural' ? image.width / image.height : VARIANTS[variantName]
    ret.variants.push({
      name: variantName,
      aspectRatio: aspectRatio,
      srcset: getSrcset(image, aspectRatio, image.variants && image.variants[variantName])
    })
  })

  return ret
}

export default createImage
export { VARIANTS }
